/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Sopotniki Admin 2.0.
  Author: Zavod Sopotniki (Markus Zevnik)
  Author URL: https://www.sopotniki.org
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Rezervacije",
    slug: "rezervacije",
    icon: "CalendarIcon",
    requiredRole: 3,
    submenu: [
      // {
      //   url: '/rezervacije/danes',
      //   name: "Danes",
      //   slug: "danes",
      //   requiredRole: 3,
      // },
      {
        url: '/rezervacije/koledar',
        name: "Koledar rezervacij",
        slug: "koledarRezervacij",
        requiredRole: 3,
      },
      {
        url: '/rezervacije/seznam',
        name: "Seznam rezervacij",
        slug: "seznamRezervacij",
        requiredRole: 3,
      },
      {
        url: '/rezervacije/intermodalni-prevozi',
        name: "Intermodalni prevozi",
        slug: "intermodalniPrevozi",
        requiredRole: 3,
      },
      {
        url: '/rezervacije/odpovedani-prevozi',
        name: "Odpovedani prevozi",
        slug: "odpovedaniPrevozi",
        requiredRole: 3,
      },
      {
        url: '/rezervacije/zavrnjeni-prevozi',
        name: "Zavrnjeni prevozi",
        slug: "zavrnjeniPrevozi",
        requiredRole: 3,
      }
    ]
  },
  {
    url: "/dnevna-porocila/seznam",
    name: "Dnevna poročila",
    slug: "dnevna-porocila",
    icon: "BookIcon",
    requiredRole: 3,
  },
  {
    url: "/mesecna-porocila/seznam",
    name: "Mesečna poročila",
    slug: "mesecna-porocila",
    icon: "FlagIcon",
    requiredRole: 3,
  },
  {
    url: "/prostovoljci",
    name: "Prostovoljci",
    slug: "prostovoljci",
    icon: "HeartIcon",
    requiredRole: 3,
  },
  {
    url: "/uporabniki",
    name: "Uporabniki",
    slug: "uporabniki",
    icon: "UsersIcon",
    requiredRole: 3,
  },
  {
    url: "/obvestila-za-uporabnike/obvestila",
    name: "Obvestila",
    slug: "obvestila",
    icon: "BellIcon",
    requiredRole: 3,
  },
  {
    url: null,
    name: "Finance",
    slug: "finance",
    icon: "DollarSignIcon",
    requiredRole: 3,
    submenu: [
      {
        url: '/gotovina/seznam',
        name: "Gotovina",
        slug: "gotovina",
        requiredRole: 3,
      },
      {
        url: '/donacije/zasebniki/seznam',
        name: "Donacije zasebnikov",
        slug: "donacijeZasebnikov",
        requiredRole: 3,
      },
      {
        url: '/donacije/podjetja',
        name: "Donacije podjetij",
        slug: "donacijePodjetij",
        requiredRole: 2,
      },
      {
        url: '/donacije/donacije-za-enote/seznam',
        name: "Donacije za enote",
        slug: "Donacije za enote",
        requiredRole: 2,
      }
    ]
  },
  {
    url: "/imenik-zdravstvenih-ustanov/imenik",
    name: "Imenik",
    slug: "imenik",
    icon: "PhoneCallIcon",
    requiredRole: 3,
  },
  {
    url: "/sluzbeni-potni-nalogi/seznam",
    name: "Službeni potni nalogi",
    slug: "sluzbeniPotniNalogi",
    icon: "FileTextIcon",
    requiredRole: 3,
  },
  // {
  //   url: "/dokumentni-sistem",
  //   name: "Dokumentni sistem",
  //   slug: "dokumentniSistem",
  //   icon: "FileIcon",
  //   requiredRole: 2,
  // },
  {
    url: "/evalvacija-dogodkov",
    name: "Evalvacija dogodkov",
    slug: "evalvacijaDogodkov",
    icon: "StarIcon",
    requiredRole: 2,
  },
  {
    url: "/upravljanje-soglasij",
    name: "Upravljanje soglasij",
    slug: "upravljanjeSoglasij",
    icon: "PenToolIcon",
    requiredRole: 2,
  },
  {
    url: "/obrazci",
    name: "Obrazci",
    slug: "obrazci",
    icon: "AlertOctagonIcon",
    requiredRole: 3,
  },
  {
    url: "/enote",
    name: "Enote",
    slug: "enote",
    icon: "MapPinIcon",
    requiredRole: 2,
  },
  {
    url: "/statistika",
    name: "Statistika",
    slug: "statistika",
    icon: "PieChartIcon",
    requiredRole: 1,
  },
  {
    url: "/optia-1000",
    name: "Optia1000",
    slug: "optia1000",
    icon: "optia1000",
    requiredRole: 1,
  },
  {
    url: "/admin-tools",
    name: "AdminTools",
    slug: "adminTools",
    icon: "SettingsIcon",
    requiredRole: 1,
  }
]
