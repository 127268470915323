<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.volunteer">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.volunteer.first_name + ' ' + activeUserInfo.volunteer.last_name}}</p>
      <small v-if="activeUserInfo.role === 1">administrator</small>
      <small v-if="activeUserInfo.role === 2">skrbnik enot</small>
      <small v-if="activeUserInfo.role === 3">koordinator enote</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.volunteer.profile_image" key="onlineImg" :src="$globalFunctions.getUserAvatar(activeUserInfo.volunteer.profile_image)" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Profile</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Inbox</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Tasks</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Chat</span>-->
<!--          </li>-->

<!--          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">-->
<!--            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />-->
<!--            <span class="ml-2">Wish List</span>-->
<!--          </li>-->

<!--          <vs-divider class="m-1" />-->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Odjava</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo () {
      return Object.keys(this.$store.state.AppActiveUser).length > 0 ? this.$store.state.AppActiveUser : JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
  },
  methods: {
    logout () {
      localStorage.removeItem('userInfo');
      // localStorage.removeItem('selectedUnit');

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/pages/login').catch(() => {})
    }
  }
}
</script>
