<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Sopotniki Admin 2.0.
      Author: Zavod Sopotniki (Markus Zevnik)
    Author URL: https://www.sopotniki.org
========================================================================================== -->


<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <p>
          <span>&copy;</span>
          <span>{{ new Date().getFullYear() }} </span>
          <a href="https://www.sopotniki.org" target="_blank" rel="nofollow">Zavod Sopotniki</a>
          <span class="hidden sm:inline-block">. Vse pravice pridržane.</span>
        </p>
      <p>
        <vs-chip transparent color="success">
          Aplikacija in njene vsebine so <span class="text-bold px-1">POSLOVNA SKRIVNOST</span> Zavoda Sopotniki.
        </vs-chip>
      </p>

    </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  }
}
</script>
