<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-if="activeUserInfo && activeUserInfo.units.length > 1">

      <div class="flex items-center">
        <div class="text-right leading-tight hidden sm:block">
          <p class="font-semibold">{{selectedUnit.name}}</p>
          <small>{{selectedUnit.coveredMunicipalities}}</small>
        </div>

        <div class="con-img ml-3">
          <vs-avatar :text="selectedUnit.name.substr(0,1) + selectedUnit.name.match(/\d/g).join('')"/>
        </div>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown unitDropdown" style="min-width: 9rem">


        <vs-dropdown-item v-for="(item, index) in activeUserInfo.units" :key="item.id" v-if="item.disabled === 0"
                          @click="setCurrentUnit(item)" class="p-0 m-0">

          <div class="flex py-2 px-0 cursor-pointer hover:bg-primary hover:text-white">
            <div class="">
              <vs-avatar :text="'E' + (index)"/>
            </div>
            <div class="pl-2">
              <div class="mt-1">{{item.name}}</div>
              <small class="block">{{item.coveredMunicipalities}}</small>
            </div>
          </div>
        </vs-dropdown-item>

      </vs-dropdown-menu>
    </vs-dropdown>
    <div v-else>
      <div class="flex items-center">
        <div class="text-right leading-tight hidden sm:block">
          <p class="font-semibold">{{selectedUnit.name}}</p>
          <small>{{selectedUnit.coveredMunicipalities}}</small>
        </div>

        <div class="con-img ml-3">
          <vs-avatar :text="selectedUnit.name.substr(0,1) + selectedUnit.name.match(/\d/g).join('')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedUnit: localStorage.getItem('selectedUnit') ? JSON.parse(localStorage.getItem('selectedUnit')) : JSON.parse(localStorage.getItem('userInfo')).units[0]
      }
    },
    computed: {
      activeUserInfo() {
        let activeUserInfoData = Object.keys(this.$store.state.AppActiveUser).length > 0 ? this.$store.state.AppActiveUser : JSON.parse(localStorage.getItem('userInfo'));
        activeUserInfoData.units = _.sortBy(activeUserInfoData.units, item => parseFloat(item.name.match(/\d/g).join('')), ['name']);
        console.log("activeUserInfoData", activeUserInfoData);
        return activeUserInfoData
      }
    },
    mounted() {
    },
    methods: {
      setCurrentUnit(unit) {
        const _this = this;

        localStorage.setItem('selectedUnit', JSON.stringify(unit));
        _this.selectedUnit = unit;

        console.log("_this.$router", _this.$router.history.current.params);
        // _this.$router.go();
        if(_this.$router.history.current.params) {
          if(_this.$router.history.current.params.reservationId) {
            _this.$router.push({name: 'Home'});
          } else if(_this.$router.history.current.params.customerId) {
            _this.$router.push({name: 'Uporabniki'});
          } else {
            _this.$router.go();
          }
        } else {
          _this.$router.go();
        }

      }
    }
  }
</script>
