<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadNotifications.length }} najnovejših</h3>
        <p class="opacity-75">obvestil</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="ntf in unreadNotifications" :key="ntf.index" class="flex justify-between px-4 py-4 notification cursor-pointer" @click="openPortal(ntf.portal)">
            <div class="flex items-start">
              <feather-icon :icon="'MessageSquareIcon'" :svgClasses="[`text-${ntf.portal === 'KP' ? 'success': 'primary'}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-${ntf.portal === 'KP' ? 'success': 'primary'}`]">{{ decodeURIComponent(ntf.title) }}</span>
                <small v-html="decodeURIComponent(ntf.content).substring(0,200) + '...'"></small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ moment(ntf.date).format('DD. MM. YYYY') }}</small>
          </li>
        </ul>
      </component>

<!--      <div class="-->
<!--        checkout-footer-->
<!--        fixed-->
<!--        bottom-0-->
<!--        rounded-b-lg-->
<!--        text-primary-->
<!--        w-full-->
<!--        p-2-->
<!--        font-semibold-->
<!--        text-center-->
<!--        border-->
<!--        border-b-0-->
<!--        border-l-0-->
<!--        border-r-0-->
<!--        border-solid-->
<!--        d-theme-border-grey-light-->
<!--        cursor-pointer">-->
<!--        <span>View All Notifications</span>-->
<!--      </div>-->
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Vue from "vue";

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      unreadNotifications: [],
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  mounted() {
    this.getLatestNews();
  },
  methods: {
    getLatestNews() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'lastChangesOnPortals')
        .then((res) => {

          _this.unreadNotifications = res.data.data.posts;

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    openPortal(portal) {
      if(portal === 'KP') {
        window.open('https://koordinatorji.sopotniki.org/');
      } else {
        window.open('https://prostovoljci.sopotniki.org/');
      }
    }

  },
}

</script>

