<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem
    ----------------------------------------------------------------------------------------
    Item Name: Sopotniki Admin 2.0.
      Author: Zavod Sopotniki (Markus Zevnik)
    Author URL: https://www.sopotniki.org
========================================================================================== -->

<template>
  <div
    class="vs-sidebar--item"
    :class="[
      {'vs-sidebar-item-active'            : activeLink},
      {'disabled-item pointer-events-none' : isDisabled}
    ]">

    <div v-if="to === '/optia-1000'">
      <router-link
        tabindex="-1"
        v-if="to"
        :class="[{'router-link-active': activeLink}]"
        :to="to"
        :target="target"
      style="margin-top: -6px">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"/>
        <img :src="getOptiaIcon" style="width: 50px; margin-left: -16px" />
        <slot/>
      </router-link>
    </div>
    <div v-if="to !== '/optia-1000'">
      <router-link
        tabindex="-1"
        v-if="to"
        :class="[{'router-link-active': activeLink}]"
        :to="to"
        :target="target">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"/>
        <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon"/>
        <slot/>
      </router-link>

      <a v-else :target="target" :href="href" tabindex="-1">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"/>
        <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon"/>
        <slot/>
      </a>
    </div>
  </div>
</template>

<script>
import optia1000icon from './../../../assets/images/content/other/optia1000.svg';

export default {
  name: 'v-nav-menu-item',
  props: {
    icon: {type: String, default: ''},
    iconSmall: {type: Boolean, default: false},
    iconPack: {type: String, default: 'material-icons'},
    href: {type: [String, null], default: '#'},
    to: {type: [String, Object, null], default: null},
    slug: {type: String, default: null},
    index: {type: [String, Number], default: null},
    featherIcon: {type: Boolean, default: true},
    target: {type: String, default: '_self'},
    isDisabled: {type: Boolean, default: false}
  },
  computed: {
    activeLink() {
      // console.log("this.$route.meta.parent", this.$route.meta.parent);
      // console.log("this.slug", this.slug);
      return !!((this.to === this.$route.path || this.$route.meta.parent === this.slug) && this.to)
    },
    getOptiaIcon() {
      return optia1000icon;
    },
    activeUserInfo () {
      return Object.keys(this.$store.state.AppActiveUser).length > 0 ? this.$store.state.AppActiveUser : JSON.parse(localStorage.getItem('userInfo'))
    }
  }
}

</script>

